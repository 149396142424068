<template>
  <div
    class="contenedorCustomSelect"
    @blur="cambiarType($event)"
    @focus="cambiarType($event)"
  >
    <div @click.stop="editable ? (cambioEstado = !cambioEstado) : false">
      <div class="caja_entrada_sel_tel inicio">
        <div class="selectCustom_tel" :class="cambioEstado ? 'active' : ''">
          <div class="contenido-select_tel">
            <div class="caja_entrada_text_tel">
              {{ textOpcionSelected }}
            </div>
          </div>
          <div><img :src="arrowHeadSelect" alt="" /></div>
        </div>

        <div class="opciones_tel" :class="cambioEstado ? 'active' : ''">
          <div class="opcionesCont">
            <div
              class="opcion"
              @click="actualizarDatos(op)"
              v-for="(op, ind) in prefijo_telef"
              :key="ind"
            >
              <div class="contenido-opcion_tel caja_entrada_text_tel">
                <div class="op_sel_tel_cod">{{ op }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    prefijo_telef: { type: Array },
    editable: Boolean,
    pre_def_prefijo_telef: String
  },
  data() {
    return {
      arrowHeadSelect: require("../../assets/imgs/arrowHeadSelect.svg"),
      arrowHeadSelectError: require("../../assets/imgs/arrowHeadSelectError.svg"),
      cambioEstado: false,
      stateInput: "inicio",
      inFocus: false,
      prefijoI18n: "Remitente",
      textOpcionSelected: "",
      primera_carga: true,
      prefijo: "",
      input_ref: null,
    };
  },
  computed: {
    ...mapState(["remitente_telef_prefijo"]),
  },
  methods: {
    ...mapMutations([""]),

    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    max_leng_cod() {
      if (this.prefijo.length > 3) {
        let aux = this.prefijo.substr(0, 3);
        this.prefijo = aux;
      }
    },

    cambiarType(evt) {
      this.$emit("cambiarType", evt);
      let evento = evt.type;
      if (evento == "focus") {
        this.stateInput = "onFocus";
        this.inFocus = true;
      } else if (evento == "blur") {
        this.stateInput = "post";
        this.inFocus = false;
        this.cambioEstado = false;
      }
    },

    llamarCambioElemento(data) {
      this.$emit("llamarCambioElemento", data);
    },

    actualizarDatos(valor) {
      this.textOpcionSelected = valor;
      this.llamarCambioElemento(valor);
    },

    establecer_condi_inicial() {
      // console.log(this.prefijo_telef);
      if (this.prefijo_telef.length == 1 && this.prefijo_telef[0] != "") {
        this.textOpcionSelected = this.prefijo_telef[0];
        this.llamarCambioElemento(this.prefijo_telef[0]);
      } else {
        if(this.pre_def_prefijo_telef != ""){
          this.textOpcionSelected = this.pre_def_prefijo_telef;
          this.llamarCambioElemento(this.textOpcionSelected);
        }else{
          this.textOpcionSelected = "";
        }
        
      }
    },
  },
  watch: {
    prefijo_telef() {
      this.establecer_condi_inicial();
    },
  },
  mounted() {
    this.establecer_condi_inicial();

    setTimeout(() => {
      this.primera_carga = false;
    }, 1000);
  },
  ionViewWillEnter() {},
};
</script>

<style >
.caja_entrada_sel_tel {
  height: 2.75rem;
  border-radius: 10px;
  padding: 0 0.6rem 0 0.4rem;
  display: flex;
  flex-direction: row;
  position: relative;
}

.caja_entrada_text_tel {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.5px;
  padding: 0.2rem 0;
}

.selectCustom_tel {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s ease all;
  padding: 0.2rem;

  z-index: 100;
}

.selectCustom_tel.active img {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}

.selectCustom_tel.active:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  bottom: -30px;
}

.tituloCustomSelect_tel {
  font-family: "Roboto";
}
.opciones_tel {
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  z-index: 300;
  width: 10rem;
  display: none;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  position: absolute;
  margin-top: 1.25rem;
  margin-left: -5px;
}
.opciones_tel.active {
  display: block;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  from {
    transform: translateY(-20px) scale(0.5);
  }
  to {
    transform: translateY(0) scale(1);
  }
}

.contenido-opcion_tel {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  transition: 0.2s ease all;
  cursor: pointer;
}

.placeHolderCustomSelect_tel {
  color: var(--customG-787878);
}
.contenido-select_tel {
  display: flex;
  justify-content: center;
}
.op_sel_tel_cod {
  width: 40%;
}
.input_tel_sel_cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.input_tel_sel_cont div {
  display: flex;
  justify-content: center;
}
.input_tel_sel {
  border-bottom: 1px solid gray;
  width: 2rem;
  background: white !important;
  border-radius: 5px 5px 0 0;
}
</style>