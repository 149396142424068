<template>
  <div>
    <div class="entradaLabelCont">
      <span v-show="variable != ''">{{
        t(
          `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
        )
      }}</span>
    </div>
    <div
      class="caja_entrada"
      :class="
        stateInput == 'noEditable'
          ? 'noEditable'
          : stateInput == 'inicio'
          ? 'inicio'
          : stateInput == 'onFocus'
          ? 'onFocus'
          : stateInput == 'post'
          ? 'post'
          : stateInput == 'onError'
          ? 'onError'
          : ''
      "
    >
      
      <input
        :type="elementosDom.tipo"
        class="inputDinamicInputBox caja_entrada_text"
        :class="stateInput == 'onError' ? 'error' : ''"
        :placeholder="`${t(
          `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
        )}${elementosDom.obligatorio ? '*' : ''}`"
        v-model="variable"
        
        autocomplete="off"
        @focus="cambiarType($event)"
        @blur="cambiarType($event)"
        
        :disabled="stateInput == 'noEditable'"
        @keyup="call_valida_basico()"
      />
    
    </div>
    <div class="mensajesForm">
      <div class="mensajeError">
        <span v-show="stateInput == 'onError'">{{
          variable == "" ? mensajeError[0] : mensajeError[1]
        }}</span>
      </div>
      <div
        :class="stateInput == 'onFocus' ? 'mensajeOkFocus' : 'mensajeOkBlur'"
        v-show="mensajeOk.mostrar"
      >
        {{ mensajeOk.texto }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    typeInput: { type: String },
    mensajeError: { type: Array },
    mensajeOk: { type: Object },
    error: { type: Boolean },
    elementosDom: { type: Object },
    indiceElementoDom: { type: Number },
  },
  computed: {
    cerrar() {
      let aux = false;
      if (!this.elementosDom.padre_ok) {
        aux = true;
      }
      return aux;
    },
  },
  data() {
    return {
      variable: "",
      stateInput: "inicio",
      ojo: require("../../assets/imgs/ojo.svg"),
      ojoCerrado: require("../../assets/imgs/ojoCerrado.svg"),
      interactuado: false,
      textoPassVisible: false,
      inFocus: false,
      prefijoI18n: "",
      primera_carga: true,
    };
  },
  methods: {
    ...mapActions([]),

    
    async cambiarType(evt) {
      let evento = evt.type;

      if (evento == "focus") {
        this.stateInput = "onFocus";
        this.inFocus = true;
      } else if (evento == "blur") {
        this.inFocus = false;

        if (this.error) {
          this.stateInput = "onError";
        } else {
          this.stateInput = "post";
        }

        this.emitir();
       
      }

      this.interactuado = true;
    },

    emitir() {
      let data = {
        elemento: this.elementosDom.identificador,
        accion: "noImporta",
        tipo_persona: this.elementosDom.tipo_persona,
      };

      data.indiceElementoDom = this.indiceElementoDom;
      data.clasetienedependiente = "";
      data.valor = this.variable;
     
      if (!this.primera_carga || (this.elementosDom.variable == "nombre2" || this.elementosDom.variable == "apellido2")) {
        this.$emit("llamarCambioElementoInput", data);
      } 

      if(this.primera_carga && this.variable != ""){
        this.stateInput = "post";
      }

    },

    setPrefijoI18n() {
      if (this.elementosDom.tipo_persona == "Remitente") {
        this.prefijoI18n = "Remitente_";
      } else if (this.elementosDom.tipo_persona == "Destinatario") {
        this.prefijoI18n = "Destinatario_";
      }
    },
    call_valida_basico(){
      this.emitir();
      this.$emit("valida_basico_", {
        variable: this.elementosDom.identificador,
         valida: this.variable
      })
    }
  },
  watch: {
   
    error() {
      if (this.error) {
        this.stateInput = "onError";
      } else {
        if (this.inFocus) {
          this.stateInput = "onFocus";
        } else {
          this.stateInput = "post";
        }
      }
    },
  },
  created() {},
  mounted() {
    // if(this.elementosDom.identificador == "ipt-Destinatario-0-telefono" || this.elementosDom.identificador == "ipt-Destinatario-0-direccion")
    // console.log(this.elementosDom.identificador, this.elementosDom.valor, this.elementosDom, this.indiceElementoDom);
    // console.log(this.elementosDom.obligatorio);
    setTimeout(() => {
      this.primera_carga = false;
    }, 1000);
    this.setPrefijoI18n();

    if (this.elementosDom.tipo == "number") {
      this.variable = parseInt(this.elementosDom.valor);
      this.emitir();
    } else {
      this.variable = this.elementosDom.valor;
      this.emitir();
    }
    if (this.elementosDom.editable == "no") this.stateInput = "noEditable";
    this.call_valida_basico();
  },
};
</script>

<style >
/* .inputBoxCompLabelCont {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 9px;
  color: var(--customG-787878);
  padding-left: 0.3rem;
  min-height: 9px;
} */

.ojo {
  width: 1rem;
  display: flex;
  justify-content: center;
}
</style>