<template>
  <div
    class="contenedorCustomSelect"
    @blur="cambiarType($event)"
    @focus="cambiarType($event)"
  >
    <div class="entradaLabelCont">
      <span v-if="Object.keys(itemSelected).length > 0">{{
        t(
          `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
        )
      }}</span>
    </div>

    <div >
      <div
        class="caja_entrada"
        :class="
          stateInput == 'noEditable'
            ? 'noEditable'
            : stateInput == 'inicio'
            ? 'inicio'
            : stateInput == 'onFocus'
            ? 'onFocus'
            : stateInput == 'post'
            ? 'post'
            : stateInput == 'onError'
            ? 'onError'
            : ''
        "
      >
        <div
          class="selectCustom"
          :class="cambioEstado ? 'active' : ''"
          @click="clickSelect"
        >
          <!-- @click="editable.editable ? cambioEstado = !cambioEstado : false" -->
          <div class="contenido-select">
            <span
              class="placeHolderCustomSelect caja_entrada_text"
              v-if="itemSelected.length == 0"
              >{{
                t(
                  `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
                )
              }}
            </span>
            <span class="tituloCustomSelect caja_entrada_text" v-else>{{
              itemSelected.pais_nom
            }}</span>
          </div>
          <div><img :src="arrowHeadSelect" alt="" /></div>
        </div>

        <div class="opciones" :class="cambioEstado ? 'active' : ''">
          <div class="opcionesCont">
            <div
              class="opcion"
              @click="setCambioSelect(op)"
              v-for="(op, ind) in paises"
              :key="ind"
            >
              <div class="contenido-opcion">
                <div class="textos">
                  <div class="tituloCustomSelectLista  caja_entrada_text">
                    <span>{{ op.pais_nom }}</span>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mensajesForm">
      <div class="mensajeError">
        <span v-show="stateInput == 'onError'">{{ mensajeError }}</span>
      </div>
      <div class="mensajeOkFocus">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "selectPaisDinamicComp",
  props: {
    elementosDom: { type: Object },
    indiceElementoDom: { type: Number },
    mensajeError: { type: String },
    error: { type: Boolean },
    primeraCarga: { type: Boolean },
    config: Object,
    forzarPrimerEmit: Boolean,
  },
  data() {
    return {
      arrowHeadSelect: require("../../assets/imgs/arrowHeadSelect.svg"),
      arrowHeadSelectError: require("../../assets/imgs/arrowHeadSelectError.svg"),
      itemSelected: [],
      cambioEstado: false,
      stateInput: "inicio",
      inFocus: false,
      prefijoI18n: "",
    };
  },
  computed: {
    ...mapState([]),

    editable() {
      let aux = { editable: true, pais: "" };
      if (this.config && !this.config.editable) {
        aux = this.config;
      }
      return aux;
    },
    paises(){
      let aux = [];
      if(this.elementosDom.elementos.length > 0){
        this.elementosDom.elementos.forEach(pais_store => {
          let aux2 = aux.findIndex(pais_local =>{return pais_local.pais_val.split("_")[0] == pais_store.pais_val.split("_")[0]});
          if(aux2 == -1){
            aux.push(pais_store);
          }
        });
        
      }
      return aux;
    }
  },
  methods: {
    ...mapMutations([""]),

    setCambioSelect(item) {
      this.cambioEstado = !this.cambioEstado;
      this.itemSelected = item;
    },
    cambiarType(evt) {
      if (this.stateInput != "noEditable") {
        let evento = evt.type;
        if (evento == "focus") {
          this.stateInput = "onFocus";
          this.inFocus = true;
        } else if (evento == "blur") {
          this.stateInput = "post";
          this.inFocus = false;
          this.cambioEstado = false;
        }
      }
    },
    setPrefijoI18n() {
      if (this.elementosDom.tipo_persona == "Remitente") {
        this.prefijoI18n = "Remitente_";
      } else if (this.elementosDom.tipo_persona == "Destinatario") {
        this.prefijoI18n = "Destinatario_";
      }
    },
    clickSelect() {
      if (this.stateInput != "noEditable") {
        this.cambioEstado = !this.cambioEstado;
      }
    },
    call_valida_basico(){
      this.$emit("valida_basico_", {
        variable: this.elementosDom.nombre_elemento,
        valida: this.variable != ""? true : false
      })
    }
  },
  watch: {
    error() {
      if (this.error) {
        this.stateInput = "onError";
      } else {
        if (this.inFocus) {
          this.stateInput = "onFocus";
        } else {
          this.stateInput = "post";
        }
      }
      console.log(this.error);
    },
    itemSelected() {
      // console.log("$$$$$$$", this.itemSelected);
      if (this.editable.editable || this.forzarPrimerEmit) {
        let data = {
          elemento: `slct-${this.elementosDom.nombre_elemento}`,
          valor: this.itemSelected.pais_val,
          accion: "change",
          tipo_persona: this.elementosDom.tipo_persona,
          indiceElementoDom: this.indiceElementoDom,
          clasetienedependiente: this.elementosDom.clasetienedependiente,
        };

        this.$emit("selectPais", data);
      }
      this.call_valida_basico();
    },
  },
  mounted() {
    this.setPrefijoI18n();
    // console.log(this.elementosDom);
    if (this.elementosDom && this.elementosDom.elementos) {
      let index = this.elementosDom.elementos.findIndex(
        (pais) => pais.selectTxt == "selected"
      );
      if (index > -1) {
        this.itemSelected = this.elementosDom.elementos[index];

        if (this.primeraCarga) {
          this.$emit("selectPais", {
            elemento: `slct-${this.elementosDom.nombre_elemento}`,
            valor: this.itemSelected.pais_val,
            accion: "change",
            tipo_persona: this.elementosDom.tipo_persona,
            indiceElementoDom: this.indiceElementoDom,
            clasetienedependiente: this.elementosDom.clasetienedependiente,
          });
        }
      }
    }

    if (!this.editable.editable) {
      this.stateInput = "noEditable";
      let pais_nom_index = this.elementosDom.elementos.findIndex((pais) => {
        return pais.pais_val == this.editable.pais_val;
      });
      if (pais_nom_index > -1) {
        setTimeout(() => {
          this.itemSelected = {
            pais_val: this.editable.pais_val,
            pais_nom: this.elementosDom.elementos[pais_nom_index].pais_nom,
          };
        }, 300);
      }

      // this.itemSelected = {pais_val: this.editable.pais_val , pais_nom: this.elementosDom.elementos[pais_nom_index].pais_nom};
    }
    this.call_valida_basico();
  },
};
</script>

<style >

/* .selectBoxCompLabelCont {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 9px;
  color: var(--customG-787878);
  padding-left: 0.3rem;
  min-height: 9px;
} */

.selectCustom {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s ease all;
  padding: 0.2rem;
  position: relative;
  z-index: 100;
}

.selectCustom.active img {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}

.selectCustom.active:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  bottom: -30px;
}

.tituloCustomSelect {
  font-family: "Roboto";
}
.tituloCustomSelectLista {
  font-family: "Roboto";
  color: var(--customG-787878);
  display: flex;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.tituloCustomSelectSigla {
  width: 3rem;
  min-width: 3rem;
}

.opciones.active {
  display: block;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  from {
    transform: translateY(-20px) scale(0.5);
  }
  to {
    transform: translateY(0) scale(1);
  }
}


.placeHolderCustomSelect {
  color: var(--customG-787878);
}
.contenido-select {
  display: flex;
  justify-content: center;
}


</style>