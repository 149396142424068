<template>
  <div
    class="contenedorCustomSelect"
    @blur="cambiarType($event)"
    @focus="cambiarType($event)"
  >
    <div class="entradaLabelCont">
      <span v-if="variable != ''">{{
        t(
          `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
        )
      }}</span>
    </div>

    <div>
      <div
        class="caja_entrada"
        :class="
          stateInput == 'noEditable'
            ? 'noEditable'
            : stateInput == 'inicio'
            ? 'inicio'
            : stateInput == 'onFocus'
            ? 'onFocus'
            : stateInput == 'post'
            ? 'post'
            : stateInput == 'onError'
            ? 'onError'
            : ''
        "
      >
        <div
          class="selectCustom"
          :class="cambioEstado ? 'active' : ''"
          @click="cambioEstado = !cambioEstado"
        >
          <div class="contenido-select">
            <span
              class="placeHolderCustomSelect caja_entrada_text"
              v-if="variable == ''"
              >{{
                t(
                  `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
                )
              }}
            </span>
            <span class="tituloCustomSelect caja_entrada_text" v-else>{{
              textOpcionSelected
            }}</span>
          </div>
          <div><img :src="arrowHeadSelect" alt="" /></div>
        </div>

        <div class="opciones" :class="cambioEstado ? 'active' : ''">
          <div class="opcionesCont">
            <div
              class="opcion"
              @click="actualizarDatos(op.value)"
              v-for="(op, ind) in elementosDom.elementos"
              :key="ind"
            >
              <div class="contenido-opcion">
                <div class="textos">
                  <span class="tituloCustomSelect caja_entrada_text">{{
                    op.text
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- @click="setCambioSelect(op)" -->
    </div>
    <div class="mensajesForm">
      <div class="mensajeError">
        <span v-show="stateInput == 'onError'">{{ mensajeError }}</span>
      </div>
      <div class="mensajeOkFocus">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    elementosDom: { type: Object },
    mostrarLabel: { type: Boolean },
    mensajeError: { type: String },
    error: { type: Boolean },
    registrando:Boolean,
  },
  data() {
    return {
      arrowHeadSelect: require("../../assets/imgs/arrowHeadSelect.svg"),
      arrowHeadSelectError: require("../../assets/imgs/arrowHeadSelectError.svg"),
      // itemSelected: [],
      cambioEstado: false,
      stateInput: "inicio",
      inFocus: false,
      prefijoI18n: "",
      variable: "",
      textOpcionSelected: "",
      primera_carga: true,
    };
  },
  computed: {
    ...mapState([]),

    fireRefresh() {
      let aux = false;
      if (this.elementosDom.fireChange) {
        aux = true;
      }
      return aux;
    },
  },
  methods: {
    ...mapMutations([""]),

    cambiarType(evt) {
      if (this.stateInput != "noEditable") {
        let evento = evt.type;
        if (evento == "focus") {
          this.stateInput = "onFocus";
          this.inFocus = true;
        } else if (evento == "blur") {
          this.stateInput = "post";
          this.inFocus = false;
          this.cambioEstado = false;
        }
      }
    },
    setPrefijoI18n() {
      if (this.elementosDom.tipo_persona == "Remitente") {
        this.prefijoI18n = "Remitente_";
      } else if (this.elementosDom.tipo_persona == "Destinatario") {
        this.prefijoI18n = "Destinatario_";
      }
    },

    llamarCambioElemento(data) {
      data.clasetienedependiente = this.elementosDom.clasetienedependiente;
      // console.log(data.elemento, data.valor, this.registrando);
      if (!this.primera_carga) {
        this.$emit("llamarCambioElemento", data);
      } // || this.elementosDom.variable == "banco_caja"        || this.elementosDom.variable == "comuna_caja"
      else if ( this.elementosDom.variable == "tipo_documento_id" || this.registrando) { //this.elementosDom.variable == "tipo_documento_id" || this.elementosDom.variable == "comuna_caja" ||
        this.$emit("llamarCambioElemento", data);
        // console.log("OK");
      }
     

      this.call_valida_basico();
    },

    actualizarDatos(valor) {
      let index = this.elementosDom.elementos.findIndex(
        (item) => item.value == valor
      );
      if (index > -1) {
        this.textOpcionSelected = this.elementosDom.elementos[index].text;
      }

      this.cambioEstado = !this.cambioEstado;
      this.variable = valor;
      this.llamarCambioElemento({
        elemento: `slct-${this.elementosDom.nombre_elemento}`,
        valor: this.variable,
        accion: "change",
        tipo_persona: this.elementosDom.tipo_persona,
        evento: 1,
      });
    },

    marcarSelected() {
      let index = this.elementosDom.elementos.findIndex(
        (item) => item.selectTxt == "selected"
      );

      if (index > -1) {
        this.variable = this.elementosDom.elementos[index].value;
        this.textOpcionSelected = this.elementosDom.elementos[index].text;

        this.llamarCambioElemento({
          elemento: `slct-${this.elementosDom.nombre_elemento}`,
          valor: this.variable,
          accion: "change",
          tipo_persona: this.elementosDom.tipo_persona,
          evento: 2,
        });
      } else {
        this.variable = "";
        this.textOpcionSelected = "";

        this.llamarCambioElemento({
          elemento: `slct-${this.elementosDom.nombre_elemento}`,
          valor: this.variable,
          accion: "change",
          tipo_persona: this.elementosDom.tipo_persona,
          evento: 3,
        });
      }
    },
    call_valida_basico() {
      this.$emit("valida_basico_", {
        variable: this.elementosDom.nombre_elemento,
        valida: this.variable != "" ? true : false,
      });
    },
  },
  watch: {
    fireRefresh() {
      if (this.fireRefresh) {
        console.log("fireRefresh");
        this.marcarSelected();
      }
    },

    error() {
      if (this.error) {
        this.stateInput = "onError";
      } else {
        if (this.inFocus) {
          this.stateInput = "onFocus";
        } else {
          this.stateInput = "post";
        }
      }
      console.log(this.error);
    },
    elementosDom() {
      if (this.elementosDom.elementos.length == 1) {
        this.variable = this.elementosDom.elementos[0].value;
        this.textOpcionSelected = this.elementosDom.elementos[0].text;
// console.log(this.textOpcionSelected, this.variable);
        this.llamarCambioElemento({
          elemento: `slct-${this.elementosDom.nombre_elemento}`,
          valor: this.variable,
          accion: "change",
          tipo_persona: this.elementosDom.tipo_persona,
          evento: 4,
        });
        this.stateInput = "post";
      }
    },
  },
  mounted() {
    
    if (this.elementosDom.editable == "no") {
      this.stateInput = "noEditable";
    }
    this.setPrefijoI18n();

    let index = this.elementosDom.elementos.findIndex((item) => {
      return item.selectTxt == "selected";
    });
    if (index > -1) {
      this.variable = this.elementosDom.elementos[index].value;
      this.textOpcionSelected = this.elementosDom.elementos[index].text;

      this.llamarCambioElemento({
        elemento: `slct-${this.elementosDom.nombre_elemento}`,
        valor: this.variable,
        accion: "change",
        tipo_persona: this.elementosDom.tipo_persona,
        evento: 4,
      });
      this.stateInput = "post";
    } else if (this.elementosDom.elementos.length == 1) {
      this.variable = this.elementosDom.elementos[0].value;
      this.textOpcionSelected = this.elementosDom.elementos[0].text;
// console.log("#####################", this.primera_carga, this.elementosDom.nombre_elemento);
      this.llamarCambioElemento({
        elemento: `slct-${this.elementosDom.nombre_elemento}`,
        valor: this.variable,
        accion: "change",
        tipo_persona: this.elementosDom.tipo_persona,
        evento: 4,
      });
      this.stateInput = "post";
    }
    setTimeout(() => {
      this.primera_carga = false;
    }, 1000);
    this.call_valida_basico();
  },
  ionViewWillEnter() {
    // console.log("#####################");
  },
};
</script>

<style >
/* .selectBoxCompLabelCont {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 9px;
  color: var(--customG-787878);
  padding-left: 0.3rem;
  min-height: 9px;
} */

.selectCustom {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s ease all;
  padding: 0.2rem;

  z-index: 100;
}

.selectCustom.active img {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}

.selectCustom.active:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  bottom: -30px;
}

.tituloCustomSelect {
  font-family: "Roboto";
}

.opciones.active {
  display: block;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  from {
    transform: translateY(-20px) scale(0.5);
  }
  to {
    transform: translateY(0) scale(1);
  }
}

/* .contenido-opcion {
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.2s ease all;
  cursor: pointer;
  padding-right: 1rem;
}
.contenido-opcion .textos {
  border-bottom: 1px solid #ececec;
  width: 100%;
} */

.placeHolderCustomSelect {
  /* font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.5625rem;
  line-height: 11px; */
  color: var(--customG-787878);
}
.contenido-select {
  display: flex;
  justify-content: center;
}
</style>