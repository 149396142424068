<template>
  <div>
    <div class="entradaLabelCont">
      <span v-show="variable != ''">{{
        t(
          `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
        )
      }}</span>
    </div>
    <div
      class="caja_entrada_tel"
      :class="
        prefijo_definido
          ? stateInput == 'noEditable'
            ? 'noEditable'
            : stateInput == 'inicio'
            ? 'inicio'
            : stateInput == 'onFocus'
            ? 'onFocus'
            : stateInput == 'post'
            ? 'post'
            : stateInput == 'onError'
            ? 'onError'
            : ''
          : 'noEditable'
      "
    >
      <select_tel_dest
        class="select_tel_cont select_tel_cont_local"
        :prefijo_telef="prefijo_telef"
        tabindex="0"
        @cambiarType="cambiarType"
        @llamarCambioElemento="set_prefijo_tel"
        :editable="prefijo_telef.length > 1"
        :pre_def_prefijo_telef="pre_def_prefijo_telef"
      />

      <input
        :type="elementosDom.tipo"
        class="inputDinamicInputBox caja_entrada_text input_tel_separador"
        :class="stateInput == 'onError' ? 'error' : ''"
        :placeholder="`${t(
          `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
        )}*`"
        v-model="variable"
        autocomplete="off"
        @focus="cambiarType($event)"
        @blur="cambiarType($event)"
        :disabled="
          stateInput == 'noEditable' || !prefijo_definido || var_prefijo == ''
        "
        @keyup="call_valida_basico()"
      />
    </div>
    <div class="mensajesForm">
      <div class="mensajeError">
        <span v-show="stateInput == 'onError'">{{
          variable == "" ? mensajeError[0] : mensajeError[1]
        }}</span>
      </div>
      <div
        :class="stateInput == 'onFocus' ? 'mensajeOkFocus' : 'mensajeOkBlur'"
        v-show="mensajeOk.mostrar"
      >
        {{ mensajeOk.texto }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { useI18n } from "vue-i18n";
import select_tel_dest from "./select_tel_dest.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    typeInput: { type: String },
    mensajeError: { type: Array },
    mensajeOk: { type: Object },
    error: { type: Boolean },
    elementosDom: { type: Object },
    indiceElementoDom: { type: Number },
  },
  components: {
    select_tel_dest,
  },
  data() {
    return {
      variable: "",
      stateInput: "inicio",
      ojo: require("../../assets/imgs/ojo.svg"),
      ojoCerrado: require("../../assets/imgs/ojoCerrado.svg"),
      interactuado: false,
      textoPassVisible: false,
      inFocus: false,
      prefijoI18n: "",
      primera_carga: true,
      prefijo_telef: [""],
      var_prefijo: "",
      pre_def_prefijo_telef: "",
    };
  },
  computed: {
    ...mapState(["remitente", "remitente_telef_prefijo"]),

    cerrar() {
      let aux = false;
      if (!this.elementosDom.padre_ok) {
        aux = true;
      }
      return aux;
    },
    prefijo_definido() {
      let aux = false;
      if (
        this.remitente.destinatarios[0].pais_moneda_destino_id &&
        this.remitente.destinatarios[0].pais_moneda_destino_id != ""
      ) {
        aux = true;
      }
      // console.log(aux);
      return aux;
    },
    prefijo_tel_react() {
      let aux = "";
      if (
        this.remitente.destinatarios[0].pais_moneda_destino_id &&
        this.remitente.destinatarios[0].pais_moneda_destino_id != ""
      ) {
        let index = this.remitente_telef_prefijo.findIndex((item) => {
          return item[0] == this.remitente.destinatarios[0].pais_moneda_destino_id.split("_")[0];
          
        });
        if (index > -1) {
          aux = this.remitente_telef_prefijo[index][2];
        }
      }
// console.log(aux);
      return aux;
    },
  },
  methods: {
    ...mapActions([]),

    async cambiarType(evt) {
      let evento = evt.type;

      if (evento == "focus") {
        this.stateInput = "onFocus";
        this.inFocus = true;
      } else if (evento == "blur") {
        this.inFocus = false;

        if (this.error) {
          this.stateInput = "onError";
        } else {
          this.stateInput = "post";
        }

        this.emitir();
      }

      this.interactuado = true;
    },

    emitir() {
      let data = {
        elemento: this.elementosDom.identificador,
        accion: "noImporta",
        tipo_persona: this.elementosDom.tipo_persona,
      };

      data.indiceElementoDom = this.indiceElementoDom;
      data.clasetienedependiente = "";
      data.valor = `${this.var_prefijo}-${this.variable}`;

      if (
        !this.primera_carga ||
        this.elementosDom.variable == "nombre2" ||
        this.elementosDom.variable == "apellido2"
      ) {
        this.$emit("llamarCambioElementoInput", data);
      }

      if (this.primera_carga && this.variable != "") {
        this.stateInput = "post";
      }
    },

    set_prefijo_tel(prefijo) {
      this.var_prefijo = prefijo;
      this.enviar_cambios();
    },

    setPrefijoI18n() {
      if (this.elementosDom.tipo_persona == "Remitente") {
        this.prefijoI18n = "Remitente_";
      } else if (this.elementosDom.tipo_persona == "Destinatario") {
        this.prefijoI18n = "Destinatario_";
      }
    },
    call_valida_basico() {
      this.emitir();
      this.$emit("valida_basico_", {
        variable: this.elementosDom.identificador,
        valida: this.variable,
      });
    },
    set_initial_cond() {
      if (this.elementosDom.valor.length > 0) {
        let separador_index = this.elementosDom.valor.indexOf("-");
        if (separador_index > -1) {
          let telef_segmentos = this.elementosDom.valor.split("-");
          if (telef_segmentos.length == 2) {
            // this.prefijo_telef = [telef_segmentos[0]];

            for (let index = 0; index < this.remitente_telef_prefijo.length; index++) {
              const pais_tel = this.remitente_telef_prefijo[index];
              if(pais_tel[0] == this.remitente.destinatarios[0].pais_moneda_destino_id.split("_")[0]){
                this.prefijo_telef = pais_tel[2];
              }
            }




            this.pre_def_prefijo_telef = telef_segmentos[0];
            this.variable = telef_segmentos[1];
            this.stateInput = "post";
          }
        }
      }
      this.call_valida_basico();
    },
    enviar_cambios() {
      let data = {
        elemento: this.elementosDom.identificador,
        accion: "noImporta",
        tipo_persona: this.elementosDom.tipo_persona,
      };

      data.indiceElementoDom = this.indiceElementoDom;
      data.clasetienedependiente = "";
      data.valor = `${this.var_prefijo}-${this.variable.trim()}`;
      this.$emit("llamarCambioElementoInput", data);
    },
  },
  watch: {
    error() {
      if (this.error) {
        this.stateInput = "onError";
      } else {
        if (this.inFocus) {
          this.stateInput = "onFocus";
        } else {
          this.stateInput = "post";
        }
      }
    },
    prefijo_tel_react() {
      if (this.prefijo_tel_react.length > 0) {
        this.prefijo_telef = this.prefijo_tel_react;
        this.var_prefijo = "";
        // console.log(this.prefijo_telef);
      }
    },
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.primera_carga = false;
    }, 1000);
    this.setPrefijoI18n();
    this.set_initial_cond();
  },
};
</script>

<style >
/* .inputBoxCompLabelCont {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 9px;
  color: var(--customG-787878);
  padding-left: 0.3rem;
  min-height: 9px;
} */

.ojo {
  width: 1rem;
  display: flex;
  justify-content: center;
}

.select_tel_cont_local {
  width: 8rem;
}
</style>